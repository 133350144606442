<template>
  <v-row class="px-5">
    <v-layout row align-center  v-if="certificacionTitulo===null">
      <v-flex sm12>
        <v-alert
          type="error"
          border="left"
          elevation="2"
          outlined
        >{{mensaje}}</v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex lg6 xs12>
        <v-textarea auto-grow :rows="1" label="Procedencia Certificación" :value="certificacionTitulo.procedencia" readonly></v-textarea>
      </v-flex>
      <v-flex lg3 xs6>
        <v-text-field label="Nro Hoja Ruta" :value="certificacionTitulo.hojaRuta" disabled></v-text-field>
      </v-flex>
      <v-flex lg2 xs6>
        <v-text-field label="Fecha Recepción" :value="formatDateText(certificacionTitulo.fechaRecepcion)" disabled></v-text-field>
      </v-flex>
    </v-layout>
  </v-row>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
export default {
  mixins:[mixinDateTime],
  data() {
    return {
      titulo: "Información de la Certificación del Título",
      mensaje: "Todavia no se registro la Certificacion del Titulo"
    };
  },
  computed: {
    certificacionTitulo() {
      return this.$store.state.abogado.certificacionTitulo;
    },
  }
};
</script>

